<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-1">
      <h4 class="mb-0 mr-1">Usuários cadastrados</h4>
    </div>

    <b-card class="card-cs-border card-cs-bg p-1 mb-0" v-show="!users.length">
      <div class="d-flex align-items-center">
        <b-img src="@/assets/images/admin/invites-empty-state.svg" width="100" class="mr-2" />
        <div class="d-flex flex-column">
          <h4>Nenhum usuário foi criado até momento.</h4>
          <span>Crie um convite de cadastro agora.</span>
          <div>
            <b-button
              variant="outline-primary"
              class="text-dark bg-white font-weight-bolder mt-1"
              @click="
                $router.push({
                  name: 'admin.assignor.economicGroup.invites',
                  query: { creating: true },
                })
              "
            >
              Criar convite
            </b-button>
          </div>
        </div>
      </div>
    </b-card>

    <div v-for="user in users" :key="user.id">
      <user-card :user="user" class="mt-1" />
    </div>
  </div>
</template>

<script>
import { BImg, BCard, BButton } from 'bootstrap-vue'
import UserCard from '@/views/admin/assignor/components/UserCard.vue'

export default {
  name: 'Users',
  components: {
    BImg,
    BCard,
    BButton,
    UserCard,
  },
  data() {
    return {
      users: [],
    }
  },
  async mounted() {
    await this.getEconomicGroupData()
  },
  methods: {
    async getEconomicGroupData() {
      try {
        const { data } = await this.$store.dispatch('admin/getEconomicGroup', this.$route.params.id)
        this.users = data.data.users
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/app-file-manager';
</style>
