<template>
  <b-card class="card-cs-border card-cs-bg mb-0">
    <div class="d-flex justify-content-between align-items-end">
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center">
          <b-card class="mb-0 card-cs-border mr-2">
            <div class="d-flex flex-row align-items-center m-auto mb-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                class="mr-2"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.2893 8L21.6226 16L28.2893 10.6667L25.6226 24H6.95597L4.28931 10.6667L10.956 16L16.2893 8Z"
                  stroke="#C9794A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div class="d-flex flex-column justify-content-between">
                <span>Tipo de usuário</span>
                <p class="mb-0 font-weight-bolder">Administrador</p>
              </div>
            </div>
          </b-card>
        </div>
        <div class="d-flex flex-column mr-2">
          <span> Nome </span>
          <span class="text-dark font-weight-bolder"> {{ user.nome }} </span>
        </div>
        <div class="d-flex flex-column mr-2">
          <span> Celular </span>
          <span class="text-dark font-weight-bolder"> {{ user.telefone }} </span>
        </div>
        <div class="d-flex flex-column mr-2">
          <span> CPF </span>
          <span class="text-dark font-weight-bolder"> {{ user.cpf }} </span>
        </div>
        <div class="d-flex flex-column mr-2">
          <span> Email </span>
          <span class="text-dark font-weight-bolder"> {{ user.email }} </span>
        </div>
      </div>
      <!-- <b-button variant="outline-primary" class="bg-white text-dark">
        <span class="font-weight-bolder"> Editar </span>
      </b-button> -->
    </div>
  </b-card>
</template>

<script>
import { BCard, BBadge, BButton, BTooltip, VBTooltip } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  name: 'UserCard',
  components: {
    BCard,
    BBadge,
    BButton,
    BTooltip,
  },
  filters: {
    formatDate,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/app-file-manager';

.card-cs-border {
  border-radius: 8px;
  border: 1px solid #becad4 !important;
  box-shadow: none !important;
}

.card-cs-bg {
  border-radius: 8px;
  background: #f8fafc;
}
</style>
